import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {useNavigate, Link} from 'react-router-dom'
import dayjs from 'dayjs'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {CardActionArea} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EventIcon from '@mui/icons-material/Event'

import PanelHead from '../../components/panel-head'
import {getEticketEvents, getEticketOrganizers, getAllMerchandise} from '../../lib/request'
import {setTabIndex as setInnerTabIndex} from '../../store/reducers/event'
import {setLoading} from '../../store/reducers/misc'
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }

  .textwrap {
    overflow-wrap: break-word;
  }

  .textIcon {
    display: inline-flex;
    align-items: center;
    gap: 5px;

    & .MuiSvgIcon-root {
      font-size: inherit;
    }
  }

  .tagsIcon {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #fff;
    border-radius: 10px;
    gap: 5px;

    & .MuiSvgIcon-root {
      font-size: inherit;
    }
  }

  .edatetime {
    display: flex;
    justify-content: space-between;
  }

`

export default function Eticket() {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {me, brandInFocus} = useSelector(state => state.profile)

	const [events, setEvents] = useState([])
	const [organizers, setOrganizers] = useState([])
	const [merchandise, setMerchandise] = useState([])

	const [tabIndex, setTabIndex] = useState(0)

	useEffect(() => {
		fetchOrganizers()
		dispatch(setInnerTabIndex(0))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		fetchEvents()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandInFocus])

	useEffect(() => {
		dispatch(setLoading(true))
		getAllMerchandise()
			.then((res) => {
				setMerchandise(res.data.rows)
			})
			.catch(err => console.log(err.response.data.message))
			.finally(() => dispatch(setLoading(false)))
		dispatch(setInnerTabIndex(0))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function fetchEvents() {
		dispatch(setLoading(true))
		getEticketEvents({brandId: brandInFocus})
			.then((res) => setEvents(res.data))
			.catch(err => console.log(err.response.data.message))
			.finally(() => dispatch(setLoading(false)))
	}

	function fetchOrganizers() {
		dispatch(setLoading(true))
		getEticketOrganizers()
			.then((res) => setOrganizers(res.data))
			.catch(err => console.log(err.response.data.message))
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyleDiv>
			<PanelHead title={'Eticket'}>
				{
					!_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 0) && (
						<Link to={'/eticket/event/create'}>
							<Button variant="outlined" fullWidth={isMobile}>New Event</Button>
						</Link>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 1) && (
						<Link to={'/eticket/organizer/create'}>
							<Button variant="outlined" fullWidth={isMobile}>New Organizer</Button>
						</Link>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 2) && (
						<Link to={'/eticket/merchandise/create'}>
							<Button variant="outlined" fullWidth={isMobile}>New Merchandise</Button>
						</Link>
					)
				}
			</PanelHead>
			<Box>
				<Paper square variant={'outlined'}>
					<Tabs
						value={tabIndex}
						onChange={(e, v) => setTabIndex(v)}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example">
						<Tab label="Events"/>
						<Tab label="Organizers"/>
						<Tab label="Merchandise"/>
					</Tabs>
				</Paper>
				<Box role={'tabpanel'} hidden={tabIndex !== 0} sx={{p: 2}}>
					{
						_.isEmpty(events) && <Alert severity={'info'}>No events available for this brand</Alert>
					}
					{
						!_.isEmpty(events) && (
							<Grid container spacing={2}>
								{
									_.isArray(me.eventAccess) && events.filter((event) => _.includes(me.eventAccess, event.id)).length > 0
										? events.filter((event) => _.includes(me.eventAccess, event.id)).map((event, index) => {

										let sx = {width: '100%', cursor: 'pointer', border: `1px solid ${theme.palette.primary.light}`}
										if (!event.isActive) {
											sx.border = `1px solid ${theme.palette.warning.light}`
										}

										return (
											<Grid xs={12} sm={6} md={6} lg={3} key={index}>
												<Card sx={sx} onClick={() => navigate(`/eticket/event/${event.id}`)}>
													<CardActionArea sx={{padding: '10px'}}>
														<Box sx={{position: 'relative'}}>
															<CardMedia
																component="img"
																height="100"
																image={event.EticketEventsMeta.find((meta) => meta.type === 'banner')?.value || 'https://storage.googleapis.com/static-oauth.revmedia.my/header-p.png'}
																alt="img-event"
															/>
														</Box>
														<CardContent sx={{height: '135px'}}>
															<Typography variant="caption" color="text.secondary" gutterBottom noWrap>
																{event.EticketOrganizer ? event.EticketOrganizer.name : ''}
															</Typography>
															<Typography gutterBottom variant="h5" component="div" noWrap>
																{event.title}
															</Typography>
															<Box className="edatetime">
																<Typography variant="subtitle2" className="textIcon" color="text.secondary" gutterBottom>
																	<EventIcon/>
																	{event.date ? dayjs(event.date).format('DD MMMM YYYY') : '-'}
																</Typography>
																<Typography variant="subtitle2" className="textIcon" color="text.secondary" gutterBottom>
																	<AccessTimeIcon/>
																	{event.time ? dayjs(event.time, 'HH:mm:ss').format('h:mm A') : '-'}
																</Typography>
															</Box>
															<Typography variant="subtitle2" className="textIcon" color="text.secondary" gutterBottom>
																<LocationOnOutlinedIcon/>
																{event.location}
															</Typography>
														</CardContent>
													</CardActionArea>
												</Card>
											</Grid>
										)
									}) : (
											<Grid xs={12} sm={12} md={12} lg={12}>
												<Alert severity="info">You don't have access to any event(s)</Alert>
											</Grid>
										)
								}
							</Grid>
						)
					}
				</Box>
				<Box role={'tabpanel'} hidden={tabIndex !== 1} sx={{p: 2}}>
					{
						_.isEmpty(organizers) && <Alert severity={'info'} sx={{width: '100%'}}>No events available for this brand</Alert>
					}
					{
						!_.isEmpty(organizers) && (
							<Grid container spacing={2}>
								{
									organizers.map((org, index) =>
										<Grid xs={12} sm={6} md={6} lg={3} key={index} onClick={() => navigate(`/eticket/organizer/${org.id}`)}>
											<Card className="evtWrap">
												<CardActionArea sx={{padding: '10px'}}>
													<Box sx={{position: 'relative'}}>
														<CardMedia
															component="img"
															height="150"
															image="https://storage.googleapis.com/static-oauth.revmedia.my/header-p.png"
															alt="img-event" />
														<Box>
															<Avatar alt="RMG" className="tagsIcon" src={org.image} sx={{width: 56, height: 56}}/>
														</Box>
													</Box>
													<CardContent>
														<Typography gutterBottom variant="h6" component="div">{org.name}</Typography>
														<Divider sx={{my: 1}}/>
														<Typography variant="caption" color="text.secondary" gutterBottom>
															{org.description}
														</Typography>
													</CardContent>
												</CardActionArea>
											</Card>
										</Grid>
									)
								}
							</Grid>
						)
					}
				</Box>
				<Box role={'tabpanel'} hidden={tabIndex !== 2} sx={{p: 2}}>
					{
						_.isEmpty(merchandise) && <Alert severity={'info'} sx={{width: '100%'}}>No events available for this brand</Alert>
					}
					{
						!_.isEmpty(merchandise) && (
							<TableContainer>
								<Table sx={{minWidth: 650}}>
									<TableHead className="tblHead">
										<TableRow>
											<TableCell>#</TableCell>
											<TableCell align="left">Title</TableCell>
											<TableCell align="left">Active?</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											merchandise.map((row,index) => {
												return (
													<TableRow key={index}
															  hover
															  sx={{cursor: 'pointer'}}
															  onClick={() => navigate(`/eticket/merchandise/${row.id}`)}>
														<TableCell>{index+1}</TableCell>
														<TableCell>{row.title}</TableCell>
														<TableCell>{row.isActive ? 'Yes' : 'No'}</TableCell>
													</TableRow>
												)
											})
										}
									</TableBody>
								</Table>
							</TableContainer>
						)
					}
				</Box>
			</Box>
		</StyleDiv>
	)
}
