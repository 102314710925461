import React from 'react'
import {Route} from 'react-router-dom'

import Admin from '../module/admin'
import AdminCreateEdit from '../module/admin/create-edit-form'
import Brand from '../module/brand'
import BrandDetail from '../module/brand/detail'
import Application from '../module/application'
import Profile from '../module/profile/listing'
import ProfileDetails from '../module/profile/detail'
import Subscription from '../module/subscription'
import SubscriptionCreate from '../module/subscription/create'
import SubscriptionDetail from '../module/subscription/detail'
import Coupon from '../module/coupon'
import CouponCreate from '../module/coupon/create'
import CouponDetail from '../module/coupon/coupon-detail'
import CheckCoupon from '../module/coupon/trace/detail'
import Payment from '../module/payment'
import PaymentDetail from '../module/payment/payment-detail'
import CredentialIndex from "../module/application/credential/index";
import CredentialCreate from '../module/application/credential/create'
import CredentialDetail from '../module/application/credential/detail'
import Eticket from '../module/eticket'
import EticketEventCreate from '../module/eticket/form/add-event'
import EticketEventDetail from '../module/eticket/detail/index'
import EticketOrganizerForm from '../module/eticket/form/add-edit-organizer'
import EticketPackageForm from '../module/eticket/form/add-edit-package'
import EticketEventMetaForm from '../module/eticket/form/add-event-meta'
import EticketMerchandiseForm from '../module/eticket/form/add-edit-merchandise'
import EticketNotAuthorized from '../module/eticket/not-authorized'
import PaymentMethod from '../module/payment-method'
import PaymentMethodDetail from '../module/payment-method/detail'
import PaymentMethodCreate from '../module/payment-method/create'
import RevId from '../module/revid'
import PaymentGroupCreate from '../module/payment-method/group/create'
import PaymentGroupDetail from '../module/payment-method/group/detail'
import AdditionalPackageCreate from '../module/subscription/additional-package/create'
import AdditionalPackageDetail from '../module/subscription/additional-package/detail'
import CampaignCreate from '../module/subscription/campaign/create'
import CampaignDetail from '../module/subscription/campaign/detail'

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import QrCodeIcon from '@mui/icons-material/QrCode';
import PaymentIcon from '@mui/icons-material/Payment'
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';

const details = {
	flat: ['admin', 'brands', 'applications', 'profiles', 'subscription', 'coupons', 'payments', 'eticket' , 'Payment Methods', 'REV.ID'],    // DON'T CHANGE ELSE EVERYTHING BROKE! ADDING IS OK
	urls: [{
		domain: 'admin',
		url: new RegExp(/^\/admin/),
		routes: (
			<>
				<Route path="/admin" element={<Admin />} />
				<Route path="/admin/:id" element={<AdminCreateEdit />} />
			</>
		),
		menu: {
			to: '/admin',
			active: new RegExp(/^\/admin/),
			icon: <EngineeringOutlinedIcon />,
			label: 'Admin'
		}
	}, {
		domain: 'brands',
		url: new RegExp(/^\/brand/),
		routes: (
			<>
				<Route path="/brand" element={<Brand/>}/>
				<Route path="/brand/:id" element={<BrandDetail/>}/>
			</>
		),
		menu: {
			to: '/brand',
			active: new RegExp(/^\/brand/),
			icon: <AccountTreeOutlinedIcon/>,
			label: 'Brands'
		}
	}, {
		domain: 'applications',
		url: new RegExp(/^\/application/),
		routes: (
			<>
				<Route path="/application" element={< Application/>}/>
				<Route path="/application/credentials/:id" element={< CredentialIndex/>}/>
				<Route path="/application/credentials/:id/create" element={< CredentialCreate/>}/>
				<Route path="/application/credentials/:id/detail/:clientId" element={<CredentialDetail/>}/>
			</>
		),
		menu: {
			to: '/application',
			active: new RegExp(/^\/application/),
			icon: <SettingsSuggestOutlinedIcon/>,
			label: 'Site applications'
		}
	}, {
		domain: 'profiles',
		url: new RegExp(/^\/profiles/),
		routes: (
			<>
				<Route path="/profiles" element={< Profile/>}/>
				<Route path="/profiles/:id" element={< ProfileDetails/>}/>
			</>
		),
		menu: {
			to: '/profiles',
			active: new RegExp(/^\/profiles/),
			icon: <PeopleOutlinedIcon/>,
			label: 'User profiles'
		}
	}, {
		domain: 'subscription',
		url: new RegExp(/^\/subscription/),
		routes: (
			<>
				<Route path="/subscription" element={<Subscription />} />
				<Route path="/subscription/create" element={<SubscriptionCreate />} />
				<Route path="/subscription/:id" element={<SubscriptionDetail />} />
				<Route path="/subscription/additional-package/create" element={<AdditionalPackageCreate />} />
				<Route path="/subscription/additional-package/:id" element={<AdditionalPackageDetail />} />
				<Route path="/subscription/campaign/create" element={<CampaignCreate />} />
				<Route path="/subscription/campaign/:id" element={<CampaignDetail />} />
			</>
		),
		menu: {
			to: '/subscription',
			active: new RegExp(/^\/subscription/),
			icon: <DisplaySettingsOutlinedIcon />,
			label: 'Subscription plan'
		}
	}, {
		domain: 'coupons',
		url: new RegExp(/^\/coupon/),
		routes: (
			<>
				<Route path="/coupon" element={< Coupon/>}/>
				<Route path="/coupon/create" element={< CouponCreate/>}/>
				<Route path="/coupon/:id" element={< CouponDetail />} />
				<Route path="/coupon/trace/:code" element={< CheckCoupon/>}/>
			</>
		),
		menu: {
			to: '/coupon',
			active: new RegExp(/^\/coupon/),
			icon: <LocalActivityOutlinedIcon/>,
			label: 'Coupons'
		}
	}, {
		domain: 'payments',
		url: new RegExp(/^\/payment/),
		routes: (
			<>
				<Route path="/payment" element={< Payment />} />
				<Route path="/payment/:id" element={< PaymentDetail />} />
			</>
		),
		menu: {
			to: '/payment',
			active: new RegExp(/^\/payment(\/|$)/),
			icon: <PaidOutlinedIcon />,
			label: 'Payments'
		}
	}, {
		domain: 'eticket',
		url: new RegExp(/^\/eticket/),
		routes: (
			<>
				<Route path="/eticket" element={< Eticket />} />
				<Route path="/eticket/event/create" element={< EticketEventCreate />} />
				<Route path="/eticket/event/:id" element={< EticketEventDetail />} />
				<Route path="/eticket/organizer/create" element={< EticketOrganizerForm />} />
				<Route path="/eticket/organizer/:id" element={< EticketOrganizerForm />} />
				<Route path="/eticket/package/create" element={< EticketPackageForm />} />
				<Route path="/eticket/package/:id" element={< EticketPackageForm />} />
				<Route path="/eticket/event/meta/create" element={< EticketEventMetaForm />} />
				<Route path="/eticket/merchandise/:id" element={< EticketMerchandiseForm />} />
				<Route path="/eticket/merchandise/create" element={< EticketMerchandiseForm />} />
				<Route path="/eticket/event/not-authorized" element={< EticketNotAuthorized />} />
			</>
		),
		menu: {
			to: '/eticket',
			active: new RegExp(/^\/eticket/),
			icon: <QrCodeIcon />,
			label: 'Eticket'
		}
	}, {
		domain: 'Payment Methods',
		url: new RegExp(/^\/payment-methods/),
		routes: (
			<>
				<Route path="/payment-methods" element={< PaymentMethod />} />
				<Route path="/payment-methods/create" element={< PaymentMethodCreate/>}/>
				<Route path="/payment-methods/:id" element={< PaymentMethodDetail />} />
				<Route path="/payment-methods/group/create" element={< PaymentGroupCreate/>}/>
				<Route path="/payment-methods/group/:id" element={< PaymentGroupDetail />} />
			</>
		),
		menu: {
			to: '/payment-methods',
			active: new RegExp(/^\/payment-methods(\/|$)/),
			icon: <PaymentIcon />,
			label: 'Payment Methods'
		}
	}, {
		domain: 'REV.ID',
		url: new RegExp(/^\/revid/),
		routes: (
			<>
				<Route path="/revid" element={<RevId />} />
			</>
		),
		menu: {
			to: '/revid',
			active: new RegExp(/^\/revid(\/|$)/),
			icon: <BiotechOutlinedIcon />,
			label: 'REV.ID'
		}
	}],
	permissions: ['Create', 'Read', 'Update', 'Delete']
}

export default details
