import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {Link, useParams} from 'react-router-dom'
import dayjs from 'dayjs'
import {useDispatch, useSelector} from 'react-redux'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import {Button, TextField} from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Alert from '@mui/material/Alert'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import InputAdornment from '@mui/material/InputAdornment'
import {DataGrid} from '@mui/x-data-grid'

import {viewSubscription, editSubscription, getPaymentMethods} from '../../lib/request'
import PanelHead from '../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import {updatePaymentMethodDropdown} from "../../store/reducers/paymentMethod";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

export default function Detail() {

	const {id} = useParams()
	const dispatch = useDispatch()
	const {me} = useSelector(state => state.profile)

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [detail, setDetail] = useState({})
	const [inLoad, setInLoad] = useState(true)
	const paymentMethodDropdown = useSelector((state) => state.paymentMethod.dropdown)
	const [dcbTokenRows, setDcbTokenRows] = useState([]);
	const [isDurationInDays, setIsDurationInDays] = useState(false)
	const [form, setForm] = useState({
		name: '',
		price: '',
		isAnnually: false,
		intervals: 0,
		isInternal: false,
		description: '',
		descriptionPublic: '',
		isRecommended: false,
		isActive: false,
		from: null,
		to: null,
		days: 0,
		isSubscribeAble: false,
		token: null
	})

	useEffect(() => {

		if (!_.isUndefined(id)) {
			dispatch(setLoading(true))
			viewSubscription(id)
				.then((res) => {
					const {data} = res
					if (_.isNull(data.days) || data.days === 0) {
						data.days = 0
					} else {
						setIsDurationInDays(true)
					}

					setDetail(data)
					setForm(res.data)
					setDcbTokenRows(res.data?.token)
					setInLoad(false)
				})
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.message))
					}
				})
				.finally(() => dispatch(setLoading(false)))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	useEffect(() => {
		if (_.isEmpty(paymentMethodDropdown)) {

			getPaymentMethods({unlimited: true})
				.then((res) => {
					dispatch(updatePaymentMethodDropdown(res.data.rows))
				})
				.catch(err => console.log(err.response.data.message))
		}
	}, [dispatch,paymentMethodDropdown])

	useEffect(() => {
		if (_.isEmpty(dcbTokenRows)) {
			setDcbTokenRows([{ channel: '', token: '' }]);
		}
	}, [dcbTokenRows]);


	const handleAddRow = () => {
		setDcbTokenRows((prevRows) => {
			const rows = _.isArray(prevRows) ? prevRows : [];
			return [...rows, { channel: '', token: '' }];
		});
	};

	const handleRemoveRow = (index) => {
		setDcbTokenRows((prevRows) => {
			const rows = _.isArray(prevRows) ? prevRows : [];
			const updatedRows = rows.filter((_, i) => i !== index);
			setForm((prevForm) => ({
				...prevForm,
				token: updatedRows,
			}));
			return updatedRows;
		});
	};

	const handleRowChange = (index, field, value) => {
		const updatedRows = dcbTokenRows.map((row, i) =>
			i === index ? { ...row, [field]: value } : row
		);

		const filteredRows = updatedRows.filter(row => row.channel || row.token);

		setDcbTokenRows(updatedRows);

		setForm((prevForm) => ({
			...prevForm,
			token: filteredRows
		}));
	}

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)

		if (name === 'intervals') {
			const v = parseInt(value)
			if (!isNaN(v) && v >= 1 && v <= 12) {
				if (v === 12) {
					formClone[name] = 1
					formClone.isAnnually = true
				} else {
					formClone[name] = value
				}
			} else if (_.isEmpty(_.trim(value))) {
				formClone[name] = value
			}
		} else if (name === 'days') {
			const v = parseInt(value)
			if (_.isEmpty(_.trim(value))) {
				formClone[name] = value
			} else if (v === 0) {
				formClone[name] = 0
				setIsDurationInDays(false)
			} else if (v > 0) {
				formClone[name] = value
			}

		} else {
			formClone[name] = value
		}


		setForm(formClone)
	}

	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v

		if (name === 'isAnnually' && v === true) {
			formClone.intervals = 1
		}

		setForm(formClone)
	}

	function submit() {

		const payload = _.pick(form, [
			'id',
			'name',
			'price',
			'isAnnually',
			'intervals',
			'isInternal',
			'description',
			'descriptionPublic',
			'from',
			'to',
			'isActive',
			'isRecommended',
			'days',
			'isSubscribeAble',
			'token'
		])

		payload.brandId = detail.Brands.id

		if (payload.days <= 0 || !isDurationInDays) {
			payload.days = 0
		}

		dispatch(setLoading(true))
		editSubscription(payload.id, payload)
			.then(() => dispatch(setSnackbarMsg('Plan updated')))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))

	}

	const collumnsDef = [{
		field: 'Email',
		headerName: 'Email',
		width: 300,
		valueGetter: params => params.row.Profile.Account.email
	}, {
		field: 'expiry',
		headerName: 'Expiry',
		width: 150,
		valueGetter: params => dayjs(params.row.expiry).format('DD MMMM YYYY')
	}, {
		field: 'notes',
		headerName: 'Notes',
		width: 200,
		sortable: false
	}]

	return (
		<Box>
			<PanelHead title={`Edit subscriptions plan`}>
				<Link to={'/subscription'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile ? 0 : 2, mb: isMobile ? 1 : 0}}>
						Back
					</Button>
				</Link>
				{
					!_.isEmpty(me) && (me.permission.includes('U')) && (
						<Button fullWidth={isMobile} variant="outlined" onClick={submit}>Save</Button>
					)
				}

			</PanelHead>
			<Box sx={{pl: 2, pr: 2}}>
				{
					inLoad ? (
						<Alert severity={'info'}>Please wait. Fetching plan information</Alert>
					) : (
						<Box component="form" noValidate onSubmit={submit}>
							<Grid container spacing={2}>
								<Grid xs={12} sm={6}>
									<Card>
										<CardContent>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Name"
												name={'name'}
												value={form.name}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
												error={_.isEmpty(_.trim(form.name))}/>
											<TextField
												fullWidth
												type="number"
												margin="normal"
												label="Price"
												name={'price'}
												value={form.price}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
												InputProps={{endAdornment: (<span>.00</span>)}}
											/>

											<TextField
												fullWidth
												multiline
												rows={3}
												type="text"
												margin="normal"
												name="description"
												label="Description internal"
												value={form.description}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}/>

											<TextField
												fullWidth
												multiline
												rows={3}
												type="text"
												margin="normal"
												label="Description public"
												name="descriptionPublic"
												value={form.descriptionPublic}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}/>

											<Grid container spacing={2}>
												<Grid xs={6}>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															label="From"
															value={form.from}
															inputFormat="DD/MM/YYYY"
															onChange={(value) => changeForm({target: {value, name: 'from'}})}
															renderInput={(params) => (
																<TextField
																	{...params}
																	fullWidth
																	margin="normal"
																	InputLabelProps={{shrink: true}}/>
															)}/>
													</LocalizationProvider>
												</Grid>
												<Grid xs={6}>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DatePicker
															label="To"
															value={form.to}
															minDate={form.from}
															inputFormat="DD/MM/YYYY"
															onChange={(value) => changeForm({target: {value, name: 'to'}})}
															renderInput={(params) => (
																<TextField
																	{...params}
																	fullWidth
																	margin="normal"
																	InputLabelProps={{shrink: true}}/>
															)}/>
													</LocalizationProvider>
												</Grid>
											</Grid>

											<FormGroup
												component="fieldset"
												sx={{mt: 3, mb: 3}}>
												<FormControlLabel
													control={<Switch checked={form.isActive} name="isActive" onChange={changeSwitch}/>}
													label="is active"/>
												<FormControlLabel
													control={< Switch checked={form.isRecommended} name="isRecommended" onChange={changeSwitch}/>}
													label="is recommended"/>
												<FormControlLabel
													control={< Switch checked={form.isInternal} name="isInternal" onChange={changeSwitch}/>}
													label="is internal plan"/>
											</FormGroup>

											<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
												<FormControlLabel
													control={<Switch checked={isDurationInDays} onChange={(e, v) => setIsDurationInDays(v)}/>}
													label="Set duration using days"
												/>
											</FormGroup>

											{
												isDurationInDays ? (
													<>
														<TextField
															fullWidth
															type="number"
															margin="normal"
															label="Durations of the plan in days"
															name={'days'}
															value={form.days}
															onChange={changeForm}
															InputLabelProps={{shrink: true}}/>
													</>
												) : (
													<>
														<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
															<FormControlLabel
																control={
																	<Switch checked={form.isAnnually} name={'isAnnually'} onChange={changeSwitch}/>
																}
																label="is annually?"
															/>
														</FormGroup>

														<TextField
															fullWidth
															type="number"
															margin="normal"
															label="Durations of the plan"
															name={'intervals'}
															value={form.intervals}
															onChange={changeForm}
															InputLabelProps={{shrink: true}}
															error={form.intervals > 12 || form.intervals < 1}
															helperText={form.isAnnually ? undefined : 'Maximum 12 month, minimum 1 month'}
															InputProps={{
																disabled: form.isAnnually,
																endAdornment: (
																	<InputAdornment position="end">{form.isAnnually ? 'year' : 'months'}</InputAdornment>
																)
															}}/>
													</>
												)
											}

										</CardContent>
									</Card>
								</Grid>
								<Grid xs={12} sm={6}>
									<Card>
										<CardHeader title={`Lists of subscribers`}/>
										<CardContent sx={{p: isMobile ? 0 : 2}}>
											{
												_.isEmpty(detail.Subscribers) ? (
													<Alert sx={{ml: isMobile ? 2 : 0, mr: isMobile ? 2 : 0}} severity={'info'}>No subscriber yet</Alert>
												) : (
                          <DataGrid
                            autoHeight
                            rows={detail.Subscribers}
                            columns={collumnsDef}
                            initialState={{pagination: {paginationModel: {pageSize: 1}}}}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                          />
												)
											}
										</CardContent>
									</Card>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Card>
										<CardHeader title={`DCB Plan Tokens`}/>
										<CardContent>
											<Grid container spacing={2}>
												<Grid xs={12} md={12} sx={{textAlign: 'right'}}>
													<Button variant="outlined" onClick={handleAddRow}>Add</Button>
												</Grid>
												{_.get(dcbTokenRows, 'length', 0) > 0 ? (
													dcbTokenRows.map((row, index) => (
													<React.Fragment key={index}>
														<Grid item xs={4}>
															<TextField
																select
																fullWidth
																margin="normal"
																label="Channel"
																InputLabelProps={{ shrink: true }}
																name={`channel-${index}`}
																value={row.channel || ''} // Assign existing channel value
																onChange={(e) => handleRowChange(index, 'channel', e.target.value)}
															>
																<MenuItem value="">
																	<em>Select Channel</em>
																</MenuItem>
																{paymentMethodDropdown.map((method) => (
																	<MenuItem key={method.id} value={method.channel_name}>
																		{method.channel_name.toUpperCase()}
																	</MenuItem>
																))}
															</TextField>
														</Grid>
														<Grid item xs={6}>
															<TextField
																fullWidth
																type="text"
																margin="normal"
																label="Token"
																name={`token-${index}`}
																InputLabelProps={{ shrink: true }}
																value={row.token || ''} // Assign existing token value
																onChange={(e) => handleRowChange(index, 'token', e.target.value)}
															/>
														</Grid>
														<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
															<IconButton edge="end" aria-label="delete" onClick={() => handleRemoveRow(index)}>
																<DeleteIcon/>
															</IconButton>
														</Grid>
													</React.Fragment>
													))
												) : (
													<Typography>No tokens</Typography>
												)}

											</Grid>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Box>
					)
				} </Box>
		</Box>
	)
}
