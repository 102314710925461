import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

const NotAuthorized = () => {
    return (
        <StyledDiv>
            <Box sx={{p: 2}}>
                <h1>Access Denied</h1>
                <p>You are not authorized to view this page.</p>
            </Box>
        </StyledDiv>
    );
};

export default NotAuthorized;
