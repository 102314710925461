import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {TextField} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Unstable_Grid2'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker'
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'


import PanelHead from '../../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {addEticketEvents, getEticketOrganizers} from '../../../lib/request'
import dayjs from 'dayjs'

const StyledDiv = styled(Box)`
  .headerCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tblHead th {
    font-weight: bolder;
  }
`

export default function CreateEvent() {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {me, brandInFocus} = useSelector((state) => state.profile)

	const [brands, setBrands] = useState(!_.isEmpty(me['Brands']) ? me['Brands'] : [])
	const [organizerDropdown, setOrganizerDropdown] = useState([])
	const [form, setForm] = useState({
		title: '',
		description: '',
		slug: '',
		date: '',
		time: '',
		location: '',
		locationLatLong: '',
		tags: '',
		fb: '',
		twitter: '',
		ig: '',
		tiktok: '',
		tnc: '',
		isActive: false,
		isHighTraffic: false,
		activeDatetime: dayjs(),
		deactiveDatetime: dayjs().add(1, 'd'),
		startPromoDate: null,
		endPromoDate: null,
		orderPrefix: '',
		organizerId: organizerDropdown.length === 1 ? organizerDropdown[0].id : '',
		titleErr: '',
		slugErr: '',
		dateErr: '',
		timeErr: '',
		locationErr: '',
		activeDatetimeErr: '',
		deactiveDatetimeErr: '',
		startPromoDateErr: '',
		endPromoDateErr: '',
		organizerIdErr: '',
		brandId: brandInFocus,
		brandIdErr: '',
		maxTicket: '',
		maxTicketErr: '',
		staffDiscount: 0,
		partnerDiscount: 0,
		userDiscount: 0,
		staffDiscountErr: '',
		partnerDiscountErr: '',
		userDiscountErr: '',
		maxTicketStaff: 0,
		maxTicketStaffErr: '',
		me: null
	})

	useEffect(() => {
		setBrands(me['Brands'])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])

	useEffect(() => {
		if (_.isEmpty(organizerDropdown)) {
			getEticketOrganizers()
				.then((res) => {
					setOrganizerDropdown(res.data)
					if (res.data.length === 1) {
						const formClone = _.cloneDeep(form)
						formClone.organizerId = res.data[0].id
						setForm(formClone)
					}
				})
				.catch(err => console.log(err.response.data.message))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizerDropdown])

	function changeForm(e) {
		const { name, value } = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		if (!_.isEmpty(value) && (name === 'maxTicket')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'staffDiscount')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'partnerDiscount')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'userDiscount')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'maxTicketStaff')) {
			formClone[name] = parseInt(value)
		}


		formClone.titleErr = ''
		formClone.slugErr = ''
		formClone.dateErr = ''
		formClone.timeErr = ''
		formClone.locationErr = ''
		formClone.activeDatetimeErr = ''
		formClone.deactiveDatetimeErr = ''
		formClone.organizerIdErr = ''
		formClone.maxTicketErr = ''
		formClone.staffDiscountErr = ''
		formClone.partnerDiscountErr = ''
		formClone.startPromoDateErr = ''
		formClone.endPromoDateErr = ''
		formClone.maxTicketStaffErr = ''
		setForm(formClone)
	}

	function changeSwitch(e, v) {
		changeForm({ target: { name: e.target, value: v } })
	}


	function submit(e) {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		const formClone = _.cloneDeep(form)

		let error = false

		if (_.isEmpty(_.trim(form.title))) {
			formClone.titleErr = 'Title is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.slug))) {
			formClone.slugErr = 'Slug is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.date))) {
			formClone.dateErr = 'Event Date is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.time))) {
			formClone.timeErr = 'Time is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.location))) {
			formClone.locationErr = 'Location is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.activeDatetime))) {
			formClone.activeDatetimeErr = 'Event active date is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.deactiveDatetime))) {
			formClone.deactiveDatetimeErr = 'Event deactive date is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.organizerId))) {
			formClone.organizerIdErr = 'Organizer is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.maxTicket))) {
			formClone.maxTicketErr = 'Max ticket value is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.maxTicketStaff))) {
			formClone.maxTicketStaffErr = 'Max ticket Staff value is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.staffDiscount))) {
			formClone.staffDiscountErr = 'Staff discount cannot be empty. Put 0 for no discount'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.partnerDiscount))) {
			formClone.partnerDiscountErr = 'Partner discount cannot be empty. Put 0 for no discount'
			error = true
		}


		if (dayjs.isDayjs(formClone.date)) {
			const eventDateTime = formClone.date.clone()
			const eventDT = eventDateTime.set('hour', formClone.time.hour()).set('minute', formClone.time.minute()).set('second', formClone.time.second())

			if (formClone.deactiveDatetime.isAfter(eventDT)) {
				formClone.deactiveDatetimeErr = 'Ticket end availability is after event date time'
				error = true
			}
		}

		formClone.slug = _.kebabCase(_.lowerCase(formClone.title))
		formClone.me = me

		if (error) {
			return setForm(formClone)
		}

		setForm(formClone)
		dispatch(setLoading(true))

		addEticketEvents(formClone)
			.then(() => {
				dispatch(setSnackbarMsg('Event created'))
				navigate('/eticket')
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDiv>
			<PanelHead title={`New Event`}>
				<Link to={'/eticket'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
				<Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
					Save
				</Button>
			</PanelHead>
			<Box sx={{p: 2}}>
				<Box component="form" noValidate onSubmit={submit}>
					<Grid container spacing={2}>
						<Grid xs={12} sm={6}>
							<Card>
								<CardHeader title={'Main details'}/>
								<CardContent>
									<Grid container spacing={2}>
										<Grid xs={12}>
											<TextField
												required
												fullWidth
												type="text"
												margin="normal"
												label="Brand"
												name="brandId"
												select
												InputLabelProps={{shrink: true}}
												error={!_.isEmpty(form.brandIdErr)}
												helperText={form.brandIdErr}
												value={form.brandId}
												onChange={changeForm}
												disabled>
												{
													brands.map((org) => (
														<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
													))
												}
											</TextField>
											<TextField
												required
												fullWidth
												type="text"
												margin="normal"
												label="Title"
												name="title"
												InputLabelProps={{shrink: true}}
												error={!_.isEmpty(form.titleErr)}
												helperText={form.titleErr}
												value={form.title}
												onChange={changeForm}/>
											<TextField
												multiline
												rows={3}
												label="Description"
												name="description"
												fullWidth
												margin="normal"
												value={form.description}
												InputLabelProps={{shrink: true}}
												onChange={changeForm}/>
											<TextField
												required
												fullWidth
												type="text"
												margin="normal"
												label="Organizer"
												name="organizerId"
												select
												InputLabelProps={{shrink: true}}
												error={!_.isEmpty(form.organizerIdErr)}
												helperText={form.organizerIdErr}
												value={form.organizerId}
												onChange={changeForm}>
												{
													organizerDropdown.map((org) => (
														<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
													))
												}
											</TextField>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												label="Location"
												name="location"
												fullWidth
												margin="normal"
												value={form.location}
												error={!_.isEmpty(form.locationErr)}
												helperText={form.locationErr}
												InputLabelProps={{shrink: true}}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												label="Location Latitude & Longitude"
												name="locationLatLong"
												fullWidth
												margin="normal"
												value={form.locationLatLong}
												InputLabelProps={{shrink: true}}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												label="Tags"
												name="tags"
												fullWidth
												margin="normal"
												value={form.tags}
												InputLabelProps={{shrink: true}}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Order Prefix"
												name="orderPrefix"
												value={form.orderPrefix}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
											/>
										</Grid>
										<Grid xs={12}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="TnC URL"
												name="tnc"
												value={form.tnc}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
											/>
										</Grid>
										<Grid xs={12} md={6}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<StaticDatePicker
													disablePast
													label="Event date"
													value={form.date}
													error={!_.isEmpty(form.dateErr)}
													helperText={form.dateErr}
													inputFormat="DD/MM/YYYY"
													onChange={(value) => changeForm({target: {value, name: 'date'}})}
													renderInput={(params) => (
														<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
													)}
												/>
											</LocalizationProvider>
										</Grid>
										<Grid xs={12} md={6}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<StaticTimePicker
													label="Event time"
													value={form.time}
													error={!_.isEmpty(form.timeErr)}
													helperText={form.timeErr}
													inputFormat="hh:mm a"
													onChange={(value) => changeForm({target: {value, name: 'time'}})}
													renderInput={(params) => (
														<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
													)}/>
											</LocalizationProvider>
										</Grid>
									</Grid>

								</CardContent>
							</Card>
						</Grid>
						<Grid xs={12} sm={6}>
							<Card sx={{mb: 2}}>
								<CardHeader title={'Ticketing detail'}/>
								<CardContent>
									<Grid container spacing={2}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<Grid xs={12} md={6}>
												<DateTimePicker
													disablePast
													label="Available date time"
													value={form.activeDatetime}
													inputFormat="DD/MM/YYYY hh:mm a"
													onChange={(value) => changeForm({target: {value, name: 'activeDatetime'}})}
													renderInput={(params) => (
														<TextField
															{...params}
															fullWidth
															margin="normal"
															InputLabelProps={{shrink: true}}
															error={!_.isEmpty(form.activeDatetimeErr)}
															helperText={!_.isEmpty(form.activeDatetimeErr)? form.activeDatetimeErr : 'Date and time when the ticket available to be purchase'}
														/>
													)}
												/>
											</Grid>
											<Grid xs={12} md={6}>
												<DateTimePicker
													minDate={form.activeDatetime.clone().add(1, 'd')}
													maxDate={dayjs.isDayjs(form.date) ? form.date : undefined}
													label="End availability date time"
													value={form.deactiveDatetime}
													inputFormat="DD/MM/YYYY hh:mm a"
													onChange={(value) => changeForm({target: {value, name: 'deactiveDatetime'}})}
													renderInput={(params) => (
														<TextField
															{...params}
															fullWidth
															margin="normal"
															InputLabelProps={{shrink: true}}
															error={!_.isEmpty(form.deactiveDatetimeErr)}
															helperText={!_.isEmpty(form.deactiveDatetimeErr) ? form.deactiveDatetimeErr : 'Date and time when ticket not available to be purchase'}
														/>
													)}
												/>
											</Grid>
										</LocalizationProvider>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Max Ticket Per User"
												name="maxTicket"
												error={!_.isEmpty(form.maxTicketErr)}
												value={form.maxTicket}
												onChange={changeForm}
												InputLabelProps={{ shrink: true }}
												helperText={!_.isEmpty(form.maxTicketErr) ? form.maxTicketErr : 'Max ticket value user can purchase'}
											/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Max Ticket Per Staff"
												name="maxTicketStaff"
												error={!_.isEmpty(form.maxTicketStaffErr)}
												value={form.maxTicketStaff}
												onChange={changeForm}
												InputLabelProps={{ shrink: true }}
												helperText={!_.isEmpty(form.maxTicketStaffErr) ? form.maxTicketStaffErr : 'Max ticket value staff can purchase'}
											/>
										</Grid>
										<Grid xs={12} md={12}>

											<FormGroup component="fieldset" sx={{ mt: 3, mb: 3 }}>
												<FormControlLabel
													control={
														<Switch checked={form.isHighTraffic} name={'isHighTraffic'} onChange={changeSwitch} />
													}
													label="Expected to be high traffic" />
											</FormGroup>
										</Grid>

										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<Grid xs={12} md={6}>
												<DateTimePicker
													disablePast
													label="Start Promo Date Time"
													value={form.startPromoDate}
													inputFormat="DD/MM/YYYY hh:mm a"
													onChange={(value) => changeForm({target: {value, name: 'startPromoDate'}})}
													renderInput={(params) => (
														<TextField
															{...params}
															fullWidth
															margin="normal"
															InputLabelProps={{shrink: true}}
															error={!_.isEmpty(form.startPromoDateErr)}
															helperText={!_.isEmpty(form.startPromoDateErr)? form.startPromoDateErr : '(eg. early bird/Tonton event exclusive)'}
														/>
													)}
												/>
											</Grid>
											<Grid xs={12} md={6}>
												<DateTimePicker
													minDate={form.startPromoDate && dayjs(form.startPromoDate).isValid() ? dayjs(form.startPromoDate).clone().add(1, 'd') : dayjs()}
													maxDate={dayjs.isDayjs(form.date) ? form.date : undefined}
													label="End Promo Date Time"
													value={form.endPromoDate}
													inputFormat="DD/MM/YYYY hh:mm a"
													onChange={(value) => changeForm({target: {value, name: 'endPromoDate'}})}
													renderInput={(params) => (
														<TextField
															{...params}
															fullWidth
															margin="normal"
															InputLabelProps={{shrink: true}}
															error={!_.isEmpty(form.endPromoDateErr)}
															helperText={!_.isEmpty(form.endPromoDateErr) ? form.endPromoDateErr : '(eg. early bird/Tonton event exclusive)'}
														/>
													)}
												/>
											</Grid>
										</LocalizationProvider>

									</Grid>
								</CardContent>
							</Card>
							<Card>
								<CardHeader title={'Media socials'}/>
								<CardContent>
									<Grid container spacing={2}>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Facebook URL"
												name="fb"
												InputLabelProps={{shrink: true}}
												value={form.fb}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Twitter URL"
												name="twitter"
												InputLabelProps={{shrink: true}}
												value={form.twitter}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="Instagram URL"
												name="ig"
												InputLabelProps={{shrink: true}}
												value={form.ig}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="tiktok URL"
												name="tiktok"
												InputLabelProps={{shrink: true}}
												value={form.tiktok}
												onChange={changeForm}/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
							<Card sx={{mt:2}}>
								<CardHeader title={'Discounts'}/>
									<CardContent>
										<Grid container spacing={2}>
											<Grid xs={12} md={6}>
												<TextField
													fullWidth
													type="text"
													margin="normal"
													label="User Discount"
													name="userDiscount"
													error={!_.isEmpty(form.userDiscountErr)}
													helperText={form.userDiscountErr}
													InputLabelProps={{shrink: true}}
													value={form.userDiscount}
													onChange={changeForm}/>
											</Grid>
											<Grid xs={12} md={6}>
												<TextField
													fullWidth
													type="text"
													margin="normal"
													label="Staff Discount"
													name="staffDiscount"
													error={!_.isEmpty(form.staffDiscountErr)}
													helperText={form.staffDiscountErr}
													InputLabelProps={{shrink: true}}
													value={form.staffDiscount}
													onChange={changeForm}/>
											</Grid>
											<Grid xs={12} md={6}>
												<TextField
													fullWidth
													type="text"
													margin="normal"
													label="Partner Discount/Special Guest Discount"
													name="partnerDiscount"
													error={!_.isEmpty(form.partnerDiscountErr)}
													helperText={form.partnerDiscountErr}
													InputLabelProps={{shrink: true}}
													value={form.partnerDiscount}
													onChange={changeForm}/>
											</Grid>
										</Grid>
									</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</StyledDiv>
	)
}
